import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'


export const getAlertCount = createAsyncThunk(
  'AlertNotification/getAlertCount',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl + `/userapi/usernotificationcount`, params, { withCredentials: true, headers })
      return {
        alertCount: response?.data?.data,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setError(e?.response?.data?.message))
      dispatch(setAlertLoader(false))
      return false
    }
  }
)

export const getNotification = createAsyncThunk(
  'AlertNotification/getNotification',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl + `/userapi/usernotificationlist`, params, { withCredentials: true, headers })
      dispatch(setLoader(false))
      return {
        notificationList: response?.data?.data?.notification_list,
        pageNumber: response?.data?.data?.pageNumber,
        pageSize: response?.data?.data?.pageSize,
        total: response?.data?.data?.total,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setError(e?.response?.data?.message))
      dispatch(setAlertLoader(false))
      return false
    }
  }
)

export const ReadNotification = createAsyncThunk(
  'AlertNotification/ReadNotification',
  async (params, { dispatch }) => {
    debugger
    
    const headers = {}
    try {
      const response = await axios.post(baseUrl + `/userapi/usernotificationread`, { notification_id: params?.notification_id }, { withCredentials: true, headers })
      dispatch(setLoader(false))
      dispatch(getAlertCount())
      dispatch(getNotification({
        notication_category_id: params?.notication_category_id, 
        pageNumber: 1,
        pageSize: 12
      }))
      return {
        notificationList: response?.data?.data?.notification_list,
        pageNumber: response?.data?.data?.pageNumber,
        pageSize: response?.data?.data?.pageSize,
        total: response?.data?.data?.total,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setError(e?.response?.data?.message))
      dispatch(setAlertLoader(false))
      return false
    }
  }
)

export const AlertNotification = createSlice({
  name: 'AlertNotification',
  initialState: {
    alertCount: {},
    notificationList: [],
    error: '',
    status: '',
    paramData: {},
    selected: null,
    pageNumber: 1,
    pageSize: 12,
    Search: '',
    total: 0,
    AlertLoader: true,
    loader: true,
  },
  reducers: {
    setSelect: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setAlertLoader: (state, action) => {
      state.AlertLoader = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setParamData: (state, action) => {
      state.paramData = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {

    builder.addCase(getAlertCount.fulfilled, (state, action) => {
      state.alertCount = action?.payload?.alertCount
      state.AlertLoader = false
    })

    builder.addCase(getNotification.fulfilled, (state, action) => {
      state.notificationList = action?.payload?.notificationList
      state.pageNumber = action?.payload?.pageNumber
      state.pageSize = action?.payload?.pageSize
      state.total = action?.payload?.total
      state.AlertLoader = false
    })

  }
})

export const { setSelect, setParamData, setAlertLoader, setLoader, setPageNo, setPageSize, setSearch } =
  AlertNotification.actions

export default AlertNotification.reducer