import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../app.config'

export const getRaiseTicketList = createAsyncThunk(
  'RaiseTicket/getRaiseTicketList',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl + '/userapi/ticketlist', params, { withCredentials: true, headers })
      return {
        RaiseTicketList: response?.data?.data,
        total: response?.data?.data?.total,
        // noofPages: response?.data?.data?.noofPages,
        pageNumber: response?.data?.data?.pageNumber,
        pageSize: response?.data?.data?.pageSize,
        to_date:response?.data?.data?.to_date,
      from_date:response?.data?.data?.from_date,
      closeTicket:response?.data?.data?.closeTicket,
      openTicket:response?.data?.data?.openTicket,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const addRaiseTicketList = createAsyncThunk(
  'RaiseTicket/addRaiseTicketList',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl + '/userapi/addticket', params, { withCredentials: true, headers })
      dispatch(getRaiseTicketList({
        pageNumber: 1,
        pageSize: 12
      }))
      return {
        RaiseTicketList: response?.data?.data?.ticket_list,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)


export const RaiseTicket = createSlice({
  name: 'RaiseTicket',
  initialState: {
    RaiseTicketList: [],
    loader: true,
    pageNumber: 1,
    pageSize: 12,
    Search: '',
    total: 0,
    to_date:"",
      from_date:"",
    openTicket:"",
    closeTicket:"",
    selected: null
  },
  reducers: {
    selectLevel: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload.pageNumber
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload.pageSize
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRaiseTicketList.fulfilled, (state, action) => {
      console.log('action?.payload', action?.payload)
      state.RaiseTicketList = action?.payload?.RaiseTicketList.ticket_list
      state.pageNumber = action?.payload?.RaiseTicketList?.pageNumber
      state.pageSize = action?.payload?.RaiseTicketList?.pageSize
      state.total = action?.payload?.RaiseTicketList?.total
      state.to_date=action?.payload?.RaiseTicketList?.to_date,
      state.from_date=action?.payload?.RaiseTicketList?.from_date,
      state.closeTicket=action?.payload?.RaiseTicketList?.closeTicket,
      state.openTicket=action?.payload?.RaiseTicketList?.openTicket,
      state.loader = false
    })

  }
})

export const { selectLevel, setLoader, setPageNo, setPageSize, setSearch } =
  RaiseTicket.actions

export default RaiseTicket.reducer