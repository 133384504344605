import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'



export const getMySubscription = createAsyncThunk(
  'MySubscription/getMySubscription',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +`/userapi/mysubscription`,{},{ withCredentials:true, headers })
      return {
        subscriptionDetails: response?.data?.data,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setError(e?.response?.data?.message))
      dispatch(setLoader(false))
      return false
    }
  }
)

export const getMyTransactions = createAsyncThunk(
  'MySubscription/getMyTransactions',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +`/userapi/myTransactions`,{},{ withCredentials:true, headers })
      return {
        transactionDetails: response?.data?.data,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setError(e?.response?.data?.message))
      dispatch(setLoader(false))
      return false
    }
  }
)

export const getTradeActivation = createAsyncThunk(
  'MySubscription/getTradeActivation',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +`/userapi/tradeactivation`,params,{ withCredentials:true, headers })
      console.log('response', response)
      return {
        status: response?.data?.data,
        tradeActivation: response?.data?.message,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setError(e?.response?.data?.message))
      dispatch(setLoader(false))
      return false
    }
  }
)

export const MySubscription = createSlice({
  name: 'MySubscription',
  initialState: {
    subscriptionDetails:{},
    transactionDetails:[],
    tradeActivation:{},
    error:'',
    status:'',
    paramData:{},
    loader:true,
    selected: null
  },
  reducers: {
    setSelect: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
        state.loader = action.payload
      },
    setParamData: (state, action) => {
      state.paramData = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMySubscription.fulfilled, (state, action) => {
      state.subscriptionDetails = action?.payload?.subscriptionDetails
      state.loader = false
    })
    builder.addCase(getMyTransactions.fulfilled, (state, action) => {
      state.transactionDetails = action?.payload?.transactionDetails
      state.loader = false
    })

    builder.addCase(getTradeActivation.fulfilled, (state, action) => {
      state.tradeActivation = action?.payload?.tradeActivation
      state.loader = false
    })
 
  }
})

export const { setSelect,setParamData,setLoader, setPageNo, setPageSize, setSearch } =
  MySubscription.actions

export default MySubscription.reducer