import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../app.config'

export const getMyWallet = createAsyncThunk(
  'MyWallet/getMyWallet',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/mywallet', {},{ withCredentials:true, headers })
      return {
        MyWallet: response?.data?.data,
        // total: response?.data?.data?.total,
        // // noofPages: response?.data?.data?.noofPages,
        // pageNumber: response?.data?.data?.pageNumber,
        // pageSize: response?.data?.data?.pageSize,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setError(e?.response?.data?.message))
      dispatch(setLoader(false))
    }
  }
)

export const getWalletHistory = createAsyncThunk(
  'MyWallet/getWalletHistory',
  async (params, { dispatch }) => {
    
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/mywallethistory', params,{ withCredentials:true, headers })
      return {
        MyWalletHistory: response?.data?.data?.wallet_transaction_list,
        total: response?.data?.data?.total,
        pageNumber: response?.data?.data?.pageNumber,
        pageSize: response?.data?.data?.pageSize,
        to_date:response?.data?.data?.to_date,
      from_date:response?.data?.data?.from_date,
      type:response?.data?.data?.type,
      // wallet_id:response?.data?.data?.wallet_id,
    }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setError(e?.response?.data?.message))
      dispatch(setLoader(false))
    }
  }
)

export const getMyWallethistoryTransferBank = createAsyncThunk(
  'MyWallet/getMyWallethistoryTransferBank',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/mywallethistoryTransferBank', params,{ withCredentials:true, headers })
      console.log('response', response?.data?.data?.wallet_transaction_list)
      return {
        MyWalletHistory: response?.data?.data?.wallet_transaction_list,
        total: response?.data?.data?.total,
        // noofPages: response?.data?.data?.noofPages,
        pageNumber: response?.data?.data?.pageNumber,
        pageSize: response?.data?.data?.pageSize,
        to_date:response?.data?.data?.to_date,
      from_date:response?.data?.data?.from_date,
      type:response?.data?.data?.type,
      // wallet_id:response?.data?.data?.wallet_id,
    }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setError(e?.response?.data?.message))
      dispatch(setLoader(false))
    }
  }
)

export const paymentWithdrawal = createAsyncThunk(
  'MyWallet/paymentWithdrawal',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/withdrawalPayment', params,{ withCredentials:true, headers })
      dispatch(setLoader(true))
      dispatch(getMyWallet())
      return {
        MyWalletHistory: response?.data?.data,
        message: response?.data?.message,
        // total: response?.data?.data?.total,
        // // noofPages: response?.data?.data?.noofPages,
        // pageNumber: response?.data?.data?.pageNumber,
        // pageSize: response?.data?.data?.pageSize,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setError(e?.response?.data?.message))
      dispatch(setLoader(false))
    }
  }
)

export const MyWallet = createSlice({
  name: 'MyWallet',
  initialState: {
    MyWallet:[],
    MyWalletHistory:[],
    MyWalletHistoryToBank:[],
    loader: true,
    message:'',
    error:'',
    pageNumber: 1,
    pageSize: 12,
    Search: '',
    total: 0,
    selected: null,
    wallet_id:"2",
    type:'A',
    to_date:"",
    from_date:"",
    wallet_id:"",
    displayMode:"wallet"
  },
  reducers: {
    setSelect: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload.pageNumber
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload.pageSize
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMyWallet.fulfilled, (state, action) => {
      //console.log('action', action)
      state.MyWallet = action?.payload?.MyWallet
      // state.pageNumber = action?.payload?.pageNumber
      // state.pageSize = action?.payload?.pageSize
      // state.total = action?.payload?.total
      state.loader = false      
    })
    builder.addCase(getWalletHistory.fulfilled, (state, action) => {
      //console.log('action', action)
      state.MyWalletHistory = action?.payload?.MyWalletHistory
      state.pageNumber = action?.payload?.pageNumber
      state.pageSize = action?.payload?.pageSize
      state.total = action?.payload?.total
      state.displayMode = "wallet"
      // state.wallet_id = action?.payload?.wallet_id
      state.loader = false      
    })
    builder.addCase(getMyWallethistoryTransferBank.fulfilled, (state, action) => {
      //console.log('action', action)
      state.MyWalletHistoryToBank = action?.payload?.MyWalletHistory
      state.pageNumber = action?.payload?.pageNumber
      state.pageSize = action?.payload?.pageSize
      state.total = action?.payload?.total
      state.wallet_id = action?.payload?.wallet_id
      state.displayMode = "bank"
      state.loader = false      
    })
  }
})

export const { setSelect,setError, setLoader, setPageNo, setPageSize, setSearch } =
  MyWallet.actions

export default MyWallet.reducer