import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../app.config'

export const getLogs = createAsyncThunk(
  'Logs/getLogs',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/userlog', params,{ withCredentials:true, headers })
      return {
        logsList: response?.data?.data.log_list,
        // noofPages: response?.data?.data?.noofPages,
        pageNumber: response?.data?.data?.pageNumber,
        pageSize: response?.data?.data?.pageSize,
         total: response?.data?.data?.total,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)


export const Logs = createSlice({
  name: 'Logs',
  initialState: {
    logsList: [],
    loader: true,
    error:'',
    TrackParam:{},
      pageNumber: 1,
      pageSize: 12,
      Search: '',
    total: 0,
    selected: null
  },
  reducers: {
    selectLevel: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload.pageNumber
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload.pageSize
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLogs.fulfilled, (state, action) => {
      state.logsList = action?.payload?.logsList
      state.pageNumber = action?.payload?.pageNumber
      state.pageSize = action?.payload?.pageSize
      state.total = action?.payload?.total
      state.loader = false
    })
   
  }
})

export const { selectLevel, setLoader, setPageNo, setPageSize, setSearch } =
  Logs.actions

export default Logs.reducer