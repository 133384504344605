import { useEffect } from 'react'; // Import useEffect for side effects
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

export const baseUrl = 'https://earn-more.co.in:8443';
export const FrontUrl = 'https://app.robotrick.in';
// export const FrontUrl = 'https://uat.robotrick.in';
// export const baseUrl = 'https://uat.robotrick.in:8443'; 


const axiosInstance = axios.create({
  baseURL: baseUrl,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('token'); // No need to parse here

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      const navigate = useNavigate(); // Move this inside the response interceptor
      toast.error('Unautherised user')
      navigate('/login');
    }
    if (error.response && error.response.status === 500) {
      toast.error('Internal server Error')
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
