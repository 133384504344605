import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../app.config'

export const getMyTeam = createAsyncThunk(
  'MyTeam/getMyTeam',
  async (params = {}, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl + '/userapi/getMyTeam', params, { withCredentials: true, headers })
      return {
        MyTeam: response?.data?.data,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)
export const getDirectReferrals = createAsyncThunk(
  'MyTeam/getDirectReferrals',
  async (params = {}, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl + '/userapi/directreferrallist', params, { withCredentials: true, headers })
      return {
        DirectReferralsDetails: response?.data?.data?.trick_list,
        pageNumber: response?.data?.data?.pageNumber,
        pageSize: response?.data?.data?.pageSize,
        total: response?.data?.data?.total,
        keyword: response?.data?.data?.keyword,
        isRoboactive: response?.data?.data?.isRoboactive,
        isTradeactive: response?.data?.data?.isTradeactive,
        designation_level: response?.data?.data?.designation_level,
        type: response?.data?.data?.type,
        order: response?.data?.data?.order,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      return {
        error: e?.response?.data?.message
      }
    }
  }
)

export const MyTeam = createSlice({
  name: 'MyTeam',
  initialState: {
    MyTeam: {},
    DirectReferralsDetails: [],
    loader: true,
    error: '',
    TrackParam: {},
    pageNumber: 1,
    pageSize: 12,
    Search: '',
    total: 0,
    selected: null,
    keyword: "",
    isRoboactive: "",
    isTradeactive: "",
    designation_level: "",
    type: "",
    order: "",
  },
  reducers: {
    selectLevel: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload.pageNumber
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload.pageSize
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMyTeam.fulfilled, (state, action) => {
      state.MyTeam = action?.payload?.MyTeam
      state.loader = false
    })
    builder.addCase(getDirectReferrals.fulfilled, (state, action) => {
      state.DirectReferralsDetails = action?.payload?.DirectReferralsDetails
      state.total = action?.payload?.total
      state.loader = false
    })
  }
})

export const { selectLevel, setLoader, setPageNo, setPageSize, setSearch } =
  MyTeam.actions

export default MyTeam.reducer